<template>
    <maintenance-page
        page-title="Vendor"
        page-icon="mdi-account-multiple-outline"
        collection-name="vendor"
        done-redirect-url="/auth/admin/vendor?isHistory=true"
    />
</template>

<script>
import MaintenancePage from '../../../components/pages/Maintenance';



export default {
    name: 'VendorDetail',
    components: { MaintenancePage },
};
</script>
